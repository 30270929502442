import React, { Component } from 'react';
import './index.scss';
import './responsive.scss'
import Markdown from '../../../../components/markdown/Markdown';

class AboutUs extends Component {

  render() {

    const props = this.props;

    return (
      <div className="P-home-about-us G-flex G-flex-column G-align-center">
        <h3>{props.home.aboutTitle}</h3>
        <Markdown data={props.home.aboutDescr} className='G-mt-5' />
      </div>
    )
  }
}

export default AboutUs;