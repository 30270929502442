import React, {useState} from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './marker';

const SimpleMap = (props) => {
  const [center] = useState({lat: props.lat, lng: props.lng });
  return (
    <div id="map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: props.apiKey }}
        defaultCenter={center}
        defaultZoom={15}
      >
        <Marker
          lat={props.lat}
          lng={props.lng}
       
        />
      </GoogleMapReact>
    </div>
  );
};

export default SimpleMap;
