import { HttpService } from "../services/connection.jsx"
import BaseStore from "./baseStore.jsx";

export default class SayHelloStore extends BaseStore {

  static dataExist = false;

  static data = {
    title: '',
    image: '',
    poster: '',
    description: '',
    headquatersTitle: '',
    headquatersSubTitle: '',
    visitUs: '',
    sendEmail: '',
    giveUs: '',
    followUs: '',
    googleUrl: '',
    isVideo: false,
  }

  static getData = async () => {
    if (!this.dataExist) {
      let data = await HttpService.find('sayhellos');
      if (data && data[0]) {
        data = data[0];
        this.data.poster = this.getImage(data.poster);
        this.data.image = this.getImage(data.image);
        this.data.title = data.title;
        this.data.description = data.description;
        this.data.isVideo = data.image && data.image.mime.includes('video');

        this.data.headquatersTitle = data.headquatersTitle;
        this.data.headquatersSubTitle = data.headquatersSubTitle;

        this.data.visitUs = data.visitUs;
        this.data.sendEmail = data.sendEmail;
        this.data.giveUs = data.giveUs;
        this.data.followUs = data.followUs;
        this.data.googleUrl = data.googleUrl;
        
        this.dataExist = true;
      }
    }
    return this.data;
  }
}
