import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import './index.scss';
import './responsive.scss'
import { ROUTES } from '../../shared/constants/routes';
import { LanguageEnum } from '../../shared/constants/language';
import { LanguageService } from '../../shared/services/languages';
import ClickOutside from '../click-outside/ClickOutside.jsx';
import Sidebar from '../sidebar/Sidebar';
import Logo from '../../assets/images/home_logo.png';

export default class Header extends Component {

  state = {
    isOpen: false,
    fixedMenu: true,
    selected: +window.localStorage.getItem('language'),
    whiteHeader: false,
    showLanguage: false,
    transparentHeader: true,
    isMobile : false
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scroll)
  }

  changeLanguage = (value) => {
    LanguageService.setLanguage(value);
  }

  scroll = () => {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      this.setState({
        whiteHeader: true,
        transparentHeader: false
      });
    } else if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
      this.setState({
        transparentHeader: true,
        whiteHeader: false,
      });
    } else {
      this.setState({
        transparentHeader: true,
        whiteHeader: false,
      });
    }
  };

  language = () => this.setState({ showLanguage: !this.state.showLanguage });

  closeDropDown = () => {
    this.setState({
      showLanguage: false
    });
  }

  open = () => {
    this.setState({ isOpen: true });
    document.body.classList.add('P-disabled');
  };
  close = () => {
    this.setState({ isOpen: false });
    document.body.classList.remove('P-disabled');
  }

  render() {
    const props = this.props;
    const state = this.state;
    return props.data && (
      <>
        <header
          className={`G-flex G-align-center 
          ${props.whiteHeader ? (state.transparentHeader && !state.whiteHeader ? 'P-transparent-header' : (state.whiteHeader ? 'P-show-header' : '')) : 'P-show-header'}`}>
          <div className="G-container">
            <div className='G-main-header G-flex G-justify-between G-align-center'>
              <div className="P-empty-div"></div>
              <div className="P-header-categories G-flex G-align-center">
                <NavLink activeClassName='G-bold' onClick={props.onClose} to={ROUTES.news} className=" P-header-tag left">{props.data.news}</NavLink>
                <NavLink activeClassName='G-bold' onClick={props.onClose} to={ROUTES.charity} className=" P-header-tag left">{props.data.charity}</NavLink>
                <NavLink to={ROUTES.home} className={`G-header-logo P-header-logo ${state.isHome ? 'G-header-logo-animation' : 'G-header-logo'} `} >
                  <img src={props.whiteHeader ? state.whiteHeader ? props.data.blackLogo : props.data.logo : props.data.blackLogo} alt="" />
                  <img src={Logo} className="P-logo-only" alt="" />
                </NavLink>
                <NavLink activeClassName='G-bold' onClick={props.onClose} to={ROUTES.about} className=" P-header-tag right">{props.data.aboutUs}</NavLink>
                <NavLink activeClassName='G-bold' onClick={props.onClose} to={ROUTES.contactUs} className=" P-header-tag right">{props.data.contact}</NavLink>
              </div>
              <div className="G-flex G-align-center">
                <div onClick={this.language} className='P-language-drop-down'>
                  {state.selected === LanguageEnum.hy ? 'ՀԱՅ' : state.selected === LanguageEnum.ru ? 'РУ' : 'EN'}
                  <ClickOutside onClickOutside={this.closeDropDown}>
                    <div className={`P-dropdown ${!state.showLanguage ? 'P-dropdown-hide' : ''}`}>
                      <span className="G-cursor G-mt-1" onClick={() => this.changeLanguage(LanguageEnum.hy)}>ՀԱՅ</span>
                      <span className="G-cursor G-mt-1" onClick={() => this.changeLanguage(LanguageEnum.ru)}>РУ</span>
                      <span className="G-cursor G-mt-1" onClick={() => this.changeLanguage(LanguageEnum.en)}>EN</span>
                    </div>
                  </ClickOutside>
                </div>
              </div>
              <div className={`P-hamburger ${props.whiteHeader ? state.transparentHeader ? 'P-hamburger-white' : '' : 'P-hamburger'}`} onClick={this.open}>
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </header>

        <Sidebar global={props.data} isOpen={state.isOpen} onClose={this.close} />
      </>
    )
  }
}
