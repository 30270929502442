import {HttpService} from "../services/connection.jsx"
import BaseStore from "./baseStore.jsx";
import { LanguageService } from "../services/languages.jsx";

export default class GlobalStore extends BaseStore {

  static dataExist = false;

  static data = {
    logoLight : '',
    logoDark  : '',
    aboutUs   : '',
    charity   : '',
    contact   : '',
    home      : '',
    news      : '',
    work      : '',
    logo      : {},
    blackLogo : '',
    phone     : '',
    email     : ''
  }

  static getData = async () => {
    if (!this.dataExist) {
      let data = await HttpService.find('global');
      if (data) {
        this.data.logoLight = this.getImage(data.logoLight);
        this.data.logoDark = this.getImage(data.logoDark);
        this.data.aboutUs = data[`aboutUs_${LanguageService.getLanguageCode()}`];
        this.data.charity = data[`charity_${LanguageService.getLanguageCode()}`];
        this.data.contact = data[`contact_${LanguageService.getLanguageCode()}`];
        this.data.home = data[`home_${LanguageService.getLanguageCode()}`];
        this.data.news = data[`news_${LanguageService.getLanguageCode()}`];
        this.data.work = data[`work_${LanguageService.getLanguageCode()}`];
        this.data.logo = this.getImage(data.logo);
        this.data.blackLogo = this.getImage(data.blackLogo);
        this.data.phone = data.phone;
        this.data.email = data.email;
        this.data.formDescr = data[`formDescr_${LanguageService.getLanguageCode()}`];
        this.data.formTitle = data[`formTitle_${LanguageService.getLanguageCode()}`];
        this.data.formModalTitle = data[`formModalTitle_${LanguageService.getLanguageCode()}`];
        this.data.formName = data[`formName_${LanguageService.getLanguageCode()}`];
        this.data.formLastName = data[`formLastName_${LanguageService.getLanguageCode()}`];
        this.data.formCompany = data[`formCompany_${LanguageService.getLanguageCode()}`];
        this.data.formEmail = data[`formEmail_${LanguageService.getLanguageCode()}`];
        this.data.formMessage = data[`formMessage_${LanguageService.getLanguageCode()}`];
        this.data.formSend = data[`formSend_${LanguageService.getLanguageCode()}`];
        this.data.thankYou = data[`thankYou_${LanguageService.getLanguageCode()}`];
        this.data.formOk = data[`formOk_${LanguageService.getLanguageCode()}`];
        this.data.formMessageSent = data[`formMessageSent_${LanguageService.getLanguageCode()}`];

        this.dataExist = true;
      }
    }
    return this.data;
  }
}
