import { LanguageEnum } from "../constants/language";


export class LanguageService {

  static getLanguageCode() {
    const lang = LanguageService.getLanguage();
    return LanguageEnum[lang];
  }

  static getLanguage() {
    return localStorage.getItem('language') || LanguageEnum.en;
  }
  
  static setLanguage(value) {
    localStorage.setItem('language', value);
    window.location.reload();
  }
}
