import {HttpService} from "../services/connection.jsx"
import BaseStore from "./baseStore.jsx";

export default class FooterStore extends BaseStore {

  static dataExist = false;

  static data = {
    address      : '',
    addressIcon  : '',
    addressLink  : '',
    copyright    : '',
    descr        : '',
    facebookLink : '',
    facebookLogo : '',
    linkedinLink : '',
    linkedinLogo : '',
    mail         : '',
    mailIcon     : '',
    phone        : '',
    phoneIcon    : '',
    twitterLink  : '',
    twitterLogo  : ''
  }

  static getData = async () => {
    if (!this.dataExist) {
      let data = await HttpService.find('footer');
      if (data) {
        this.data.address = data.address;
        this.data.descr = data.descr;
        this.data.mail = data.mail;
        this.data.phone = data.phone;
        this.data.copyright = data.copyright;
        this.data.linkedinLogo = this.getImage(data.linkedinLogo);
        this.data.facebookLogo = this.getImage(data.facebookLogo);
        this.data.twitterLogo = this.getImage(data.twitterLogo);
        this.data.facebookLink = data.facebookLink;
        this.data.linkedinLink = data.linkedinLink;
        this.data.twitterLink = data.twitterLink;
        this.data.phoneIcon =  this.getImage(data.phoneIcon);
        this.data.mailIcon = this.getImage(data.mailIcon);
        this.data.addressIcon = this.getImage(data.addressIcon);

        this.dataExist = true;
      }
    }
    return this.data;
  }
}
