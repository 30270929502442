
export default class BaseStore {

  static getImage = (data) => {
    if (data) {
      return process.env.REACT_APP_API_URL + data.url.slice(1);
    }
    return null;
  }

  static getImages = (data) => {
    if (data && data.length > 0) {
      return process.env.REACT_APP_API_URL + data[0].url.slice(1);
    }
    return null;
  }
}