import React, {Component} from 'react';

import {ByRoute} from "../../shared/decorators/routes";
import {ROUTES} from "../../shared/constants/routes";

import './index.scss';
import HomeStore from "../../shared/api/home";
import Logo from "../../assets/images/home_logo.png";
import Modal from "./components/modal/modal";
import GlobalStore from "../../shared/api/global";

@ByRoute(ROUTES.login)
class Login extends Component {

  state = {
    data: null,
    image: '',
    modalState: false,
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    message: '',
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    companyError: false,
  }

  async componentDidMount() {
    const data = await HomeStore.getHomeData();
    this.setState({data, image: data.coverImages[0]});
  }

  setModalState = (modalState) => {
    this.setState({modalState});
  }

  submit = () => {
    if (this.validate()) {
      this.setModalState(true);
      fetch(process.env.REACT_APP_API_URL + 'verify/sendMessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          companyName: this.state.company,
          email: this.state.email,
          message: this.state.message,
        }),
      }).then();
    }
  }

  handleName = (event) => {
    this.setState({firstName: event.target.value});
  }

  handleLastName = (event) => {
    this.setState({lastName: event.target.value});
  }

  handleEmail = (event) => {
    this.setState({email: event.target.value});
  }

  handleMessage = (event) => {
    this.setState({message: event.target.value});
  }

  handleCompany = (event) => {
    this.setState({company: event.target.value});
  }

  validate = () => {
    let valid = true;

    if (this.state.firstName) {
      this.setState({ firstNameError: false });
    } else {
      this.setState({ firstNameError: true });
      valid = false;
    }

    if (this.state.lastName) {
      this.setState({ lastNameError: false });
    } else {
      this.setState({ lastNameError: true });
      valid = false;
    }

    if (this.state.email) {
      this.setState({ emailError: false });
    } else {
      this.setState({ emailError: true });
      valid = false;
    }

    if (this.state.company) {
      this.setState({ companyError: false });
    } else {
      this.setState({ companyError: true });
      valid = false;
    }

    return valid;
  }

  closeModalAndReset = () => {
    this.setModalState(false);
    this.setState({
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      message: '',
    });
  }

  render() {
    const global = GlobalStore.data;
    const {state} = this;
    return state.data && (
      <div className="P-login" style={{backgroundImage: `url('${state.image}')`}}>
        <div className="P-cover G-flex">
          <div className="P-half"></div>
          <div className="P-half G-flex G-center">
            <div className="G-flex G-flex-column P-form">
              <img src={Logo} alt=""/>
              <h4>{global.formTitle}</h4>
              <p>{global.formDescr}</p>
              <div className="G-flex G-flex-column">
                <input type='text' className="G-input G-mb-2" placeholder={global.formCompany} onChange={this.handleCompany} value={state.company}/>
                <input type='text' className="G-input G-mb-2" placeholder={global.formName} onChange={this.handleName} value={state.firstName}/>
                <input type='text' className="G-input G-mb-2" placeholder={global.formLastName} onChange={this.handleLastName} value={state.lastName}/>
                <input type='text' className="G-input G-mb-2" placeholder={global.formEmail} onChange={this.handleEmail} value={state.email}/>
                <input type='text' className="G-input" placeholder={global.formMessage} onChange={this.handleMessage} value={state.message}/>
              </div>
              <button className="G-btn G-mt-6" onClick={this.submit}>{global.formSend}</button>
            </div>
          </div>
        </div>

        {state.modalState ? <Modal close={this.closeModalAndReset}/> : null}
      </div>
    );
  }

}

export default Login;
