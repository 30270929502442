import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { ByRoute } from '../../shared/decorators/routes.jsx';
import { ROUTES } from '../../shared/constants/routes.jsx';

import './index.scss';
import './responsive.scss'

import Cover from '../../components/cover/Cover.jsx';
import test from '../../assets/images/animation-logo.png';
import CharityPagesStore from '../../shared/api/charity-page.jsx';
import CharitiesStore from '../../shared/api/charities.jsx';

@ByRoute(ROUTES.charity)
class Charity extends Component {
  _isMounted = false;

  state = {
    data: null,
    charities: [],
    page: 1,
    totalPages: 0,
    animation: false
  }

  async componentDidMount() {
    const [data, charities] = await Promise.all([
      CharityPagesStore.getData(),
      CharitiesStore.getData()
    ]);

    this.setState({
      data,
      charities: [...charities].sort((a, b) => b.created_at - a.created_at)
    },
      () => this.checkPages());
    window.addEventListener('scroll', this.isScrolledIntoView)
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('scroll', this.isScrolledIntoView)
  }

  incrementPage = () => {
    this.setState({ page: this.state.page + 1 });
  }

  checkPages = () => {
    this.setState({ totalPages: Math.ceil(this.state.charities.length / 1) });
  }

  isScrolledIntoView = () => {
    var el = document.getElementById('myElement');
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    if (elemTop > 0) {
      this.setState({
        animation: true
      })
    }
  }

  render() {
    const state = this.state;
    return (
      <div className="P-charity G-page">
        {state.data && <Fragment>
          <div className="P-slider-padding">
            <Cover title={state.data.coverTitle} image={state.data.coverImage} />
          </div>
          <div className="P-green-line" />
          <div className="P-news-items G-flex G-align-center">
            <div className="P-left">
              <img src={test} alt="" className={` ${state.animation ? 'animation' : ''}`} />
              <div className="P-news-block G-flex G-flex-column G-align-center" id="myElement">
                {state.charities.slice(0, state.page * 10).map((item, index) => (
                  <Link to={`${ROUTES.charityDetails}/${item.id}`} className="P-news-item G-flex G-mb-6" key={index}>
                    <div className="P-news-image" style={{ backgroundImage: `url('${item.coverImage}')` }}> </div>
                    <div className="G-flex G-flex-column G-ml-3">
                      <h2> {item.title} </h2>
                      <p className="G-mt-2"> {item.shortDescription}</p>
                      <Link to={`${ROUTES.charityDetails}/${item.id}`} className="G-bold G-gold G-mt-1">{state.data.showMore}</Link>
                    </div>
                  </Link>
                ))}
                {state.page > state.totalPages ?
                  <p className="P-show-more G-cursor" onClick={this.incrementPage}>{state.data.showMore}</p>
                  : null}
              </div>
            </div>
            <div className="P-right"></div>
          </div>
        </Fragment>}
      </div>
    )
  }
}

export default Charity;
