import React from 'react';
import './index.scss'

const Marker = (props) => {
  return (
    <div className="marker">
      <i className="icon-location"/>
    </div>
  );
};

export default Marker;