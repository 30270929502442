import { HttpService } from "../services/connection.jsx"
import BaseStore from "./baseStore.jsx";
import { LanguageService } from "../services/languages.jsx";

export default class AboutStore extends BaseStore {

  static dataExist = false;

  static data = {
    coverTitle        : '',
    coverImage        : '',
    aboutTitle        : '',
    aboutDescr        : '',
    workImage         : '',
    workTitle         : '',
    workDescr         : '',
    ourTeam           : ''
  }

  static getData = async () => {
    if (!this.dataExist) {
      let data = await HttpService.find('about-us');
      if (data) {
        this.data.coverTitle = data[`coverTitle_${LanguageService.getLanguageCode()}`];
        this.data.coverImage = this.getImage(data.coverImage)
        this.data.aboutDescr = data[`aboutDescr_${LanguageService.getLanguageCode()}`];
        this.data.aboutTitle = data[`aboutTitle_${LanguageService.getLanguageCode()}`];
        this.data.workTitle = data[`workTitle_${LanguageService.getLanguageCode()}`];
        this.data.workDescr = data[`workDescr_${LanguageService.getLanguageCode()}`];
        this.data.workImage = this.getImage(data.workImage)
        this.data.ourTeam = data[`ourTeam_${LanguageService.getLanguageCode()}`];
        
        this.dataExist = true;
      }
    }
    return this.data;
  }
}
