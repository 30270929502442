import { HttpService } from "../services/connection.jsx"
import BaseStore from "./baseStore.jsx";
import { LanguageService } from "../services/languages.jsx";

export default class TeamStore extends BaseStore {

  static dataExist = false;
  static getData = async () => {
    if (!this.dataExist) {
      let data = await HttpService.find('teams');
      if (data) {
        this.data = data.map(x => {
          return {
            fullName : x[`fullName_${LanguageService.getLanguageCode()}`],
            position : x[`position_${LanguageService.getLanguageCode()}`],
            image    : this.getImage(x.image),
          }
        });
      }
    }
    return this.data;
  }
}
