import Strapi from 'strapi-sdk-javascript/build/main';


export class HttpService {

  static strapi = new Strapi(process.env.REACT_APP_API_URL);

  static find = async (name) => {
    try {
      const data = await this.strapi.getEntries(name);
      return data || []; 
    } catch (error) {
      return [];
    }
  }

  static findOne = async (name, id) => {
    try {
      const data = await this.strapi.getEntry(name, id);
      return data || null; 
    } catch (error) {
      return null;
    }
  }
} 