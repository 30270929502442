import { HttpService } from "../services/connection.jsx"
import BaseStore from "./baseStore.jsx";
import { LanguageService } from "../services/languages.jsx";

export default class BusinessStore extends BaseStore {

  static dataExist = false;

  static data = []

  static getData = async () => {
    if (!this.dataExist) {
      let data = await HttpService.find('businesses');
      if (data) {
        this.data = data.map(x => {
          return {
            id             : x.id,
            buttonUrl      : x.buttonUrl,
            title          : x[`title_${LanguageService.getLanguageCode()}`],
            lat            : x.lat,
            lng            : x.lng,
            linkButton     : x.linkButton,
            position       : x.position,
            phone          : x.phone,
            email          : x.email,
            address        : x.address,
            icon           : this.getImage(x.icon),
            businessBlocks : x.businessBlocks.map(y => {
              let video = y[`video_${LanguageService.getLanguageCode()}`];
              if (video && Array.isArray(video)) {
                video = video[0];
              }

              return {
                description : y[`description_${LanguageService.getLanguageCode()}`],
                images      : (y.images || []).map(z => this.getImage(z)),
                id          : y.id,
                position    : y.position,
                icon        : this.getImage(y.icon),
                video       : this.getImage(video),
              }
            })                                               
          }
        });

        this.dataExist = true;
      }
    }
    return this.data;
  }
}
