import React from 'react';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import * as qs from 'query-string';

import RouteService from './shared/services/routes.jsx';
import './modules';
import GlobalStore from './shared/api/global.jsx';
import Header from './components/header/Header.jsx';
import {ROUTES} from './shared/constants/routes.jsx';
import Footer from './components/footer/Footer.jsx';

window.routerHistory = createBrowserHistory();
window.routerHistory.listen(() => window.scrollTo(0, 0));

class App extends React.Component {

  state = {
    data: null,
    whiteHeader: false,
    canLogin: false,
  }

  async componentDidMount() {
    this.checkPermission();
    const data = await GlobalStore.getData();
    this.setState({data});
    this.checkRoute(window.routerHistory.location.pathname);

    window.routerHistory.listen(({pathname}) => {
      this.checkRoute(pathname);
    });
  }

  async checkPermission() {
    const userConfirmId = localStorage.getItem('userConfirmId');
    // this.setState({canLogin: true});
    // return;
    if (userConfirmId) {
      const checked = await this.check(userConfirmId);
      if (!checked) {
        localStorage.removeItem('userConfirmId');
        this.navigate();
      } else {
        this.setState({canLogin: true});
      }
      return;
    }

    const parsed = qs.parse(window.location.search);
    if (parsed.userConfirmId && !Number.isNaN(parsed.userConfirmId)) {
      localStorage.setItem('userConfirmId', parsed.userConfirmId);
      const checked = await this.check(parsed.userConfirmId);
      if (!checked) {
        localStorage.removeItem('userConfirmId');
        return this.navigate();
      } else {
        this.setState({canLogin: true});
      }
    } else {
      this.navigate();
    }
  }

  checkRoute = (path) => {
    const newValue = path.includes(ROUTES.home);

    if (newValue !== this.state.whiteHeader) {
      this.setState({whiteHeader: newValue});
    }
  }

  navigate() {
    window.routerHistory.push('/login');
  }

  async check(id) {
    const res = await fetch(process.env.REACT_APP_API_URL + `verify/isVerified?token=${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return await res.json();
  }

  render() {
    const {state} = this;
    return this.state.data && (
      <Router history={window.routerHistory}>
        {state.canLogin ? <Header whiteHeader={this.state.whiteHeader} data={this.state.data}/> : null}
        <Switch>
          {RouteService.subscribeUnauthorized(routes => routes.map(item =>
            <Route
              exact={true}
              key={item.path}
              path={item.path}
              component={item.component}
            />
          ))}
          <Redirect to={RouteService.defaultRoute}/>
        </Switch>
        {state.canLogin ? <Footer data={this.state.data}/> : null}
      </Router>
    )
  }
}

export default App;
