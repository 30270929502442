import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss';
import './responsive.scss'
import { ROUTES } from '../../shared/constants/routes';
import GlobalStore from '../../shared/api/global';
import FooterStore from '../../shared/api/footer';
import Markdown from '../markdown/Markdown';

export default class Footer extends React.Component {

  state = {
    data: null,
  }

  async componentDidMount() {
    const data = await FooterStore.getData();
    this.setState({ data });
  }

  render() {
    const global = GlobalStore.data;
    const props = this.props;
    const { data } = this.state;
    return data && (
      <footer className='G-flex G-flex-column '>
        <Link to={ROUTES.home} className="G-header-logo">
          <img src={props.data.logo} alt="" />
        </Link>
        <div className="G-flex G-align-center G-justify-between G-align-start G-mt-5">
          <div className="P-footer-link P-middle G-flex G-flex-row G-flex-column">
            <Markdown data={data.descr} />
            <div className="P-row G-flex G-align-center G-justify-between G-mt-3 P-footer-contact-info">
              <div className="G-flex G-align-center G-mr-3"> <img src={data.phoneIcon} alt=""  /> <span className='G-ml-1'>{data.phone}</span></div>
              <div className="G-flex G-align-center G-mr-3"> <img src={data.mailIcon} alt=""  /> <span className='G-ml-1'>{data.mail}</span></div>
              <div className="G-flex G-align-center G-mr-3"> <img src={data.addressIcon} alt=""  /> <span className='G-ml-1'>{data.address}</span></div>
            </div>
          </div>
          <div className="P-footer-contact G-flex G-flex-wrap">
            <Link to={ROUTES.home}>{global.home}</Link>
            {/* <Link to={ROUTES.charity} className="G-ml-2">{global.charity}</Link>
            <Link to={ROUTES.about}>{global.aboutUs}</Link>
            <Link to={ROUTES.news} className="G-ml-2">{global.news}</Link>
            <Link to={ROUTES.contactUs}>{global.contact}</Link> */}
            <div to="" className="G-ml-2">
              <a href={data.twitterLink} target='blank' className='G-mr-2'> <img src={data.twitterLogo} alt="" /> </a>
              <a href={data.facebookLink} target='blank' className='G-mr-2'> <img src={data.facebookLogo} alt="" /> </a>
              <a href={data.linkedinLink} target='blank' className='G-mr-2'> <img src={data.linkedinLogo} alt="" /> </a>
            </div>
          </div>
        </div>
        <div className="P-footer-end G-flex G-align-center G-justify-center G-mt-3">
          <p className="G-fs-16 G-mt-1 G-mb-1" >{data.copyright}</p>
        </div>
      </footer>
    )
  }
}
