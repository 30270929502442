
import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss';
import './responsive.scss';
import SidebarStore from '../../shared/api/sidebar';
import { ROUTES } from '../../shared/constants/routes';
import test from '../../assets/images/animation.png';

export default class Sidebar extends React.Component {

  state = {
    data: null
  }

  async componentDidMount() {
    const data = await SidebarStore.getData();
    this.setState({ data });
  }

  render() {
    const state = this.state;
    const props = this.props;

    return state.data && (
      <nav className={`${props.isOpen ? 'P-open' : ''}`}>
        <div className='G-container'>
          <div className="P-top G-flex G-justify-between G-align-center G-mb-4">
            <Link onClick={props.onClose} to={ROUTES.home}> </Link>
            <div className="P-close-wrap G-cursor" onClick={props.onClose}>
              <div className="P-close" />
            </div>
          </div>
          <div className="P-mid G-flex G-justify-between G-align-end G-text-right G-mb-6">
            <div className="P-image G-bg-image" style={{ backgroundImage: `url('${state.data.image}')` }}></div>
            <div className="P-right">

              <div className="P-sidebar-links">
                <Link onClick={props.onClose} to={ROUTES.home} className="P-menu-item">{props.global.home}</Link>
              </div>

              <div className="P-sidebar-links">
                <Link onClick={props.onClose} to={ROUTES.about} className="P-menu-item">{props.global.aboutUs}</Link>
              </div>

              <div className="P-sidebar-links">
                <Link onClick={props.onClose} to={ROUTES.news} className="P-menu-item">{props.global.news}</Link>
              </div>

              <div className="P-sidebar-links">
                <Link onClick={props.onClose} to={ROUTES.charity} className="P-menu-item">{props.global.charity}</Link>
              </div>

              <div className="P-sidebar-links">
                <Link onClick={props.onClose} to={ROUTES.contactUs} className="P-menu-item">{props.global.contact}</Link>
              </div>

            </div>
          </div>
          <div className="P-footer G-flex G-justify-between G-align-end">
            <p className="P-desc">{state.data.description}</p>

            <div className="P-right G-flex G-justify-end G-text-right">

            </div>
          </div>
        </div>
        <img className="P-sidebar-img" src={test} alt="" />
      </nav>
    )
  }
}
