import { HttpService } from "../services/connection.jsx"
import BaseStore from "./baseStore.jsx";
import { LanguageService } from "../services/languages.jsx";

export default class CharitiesStore extends BaseStore {

  static dataExist = false;

  static data = []

  static getData = async () => {
    if (!this.dataExist) {
      let data = await HttpService.find('charity-items');
      if (data) {
        this.data = data.map(x => {
          return {
            id               : x.id,
            coverImage       : this.getImage(x.coverImage),
            shortDescription : x[`shortDescription_${LanguageService.getLanguageCode()}`],
            title            : x[`title_${LanguageService.getLanguageCode()}`],
            charityBlocks    : x.charityBlocks.map(y => {
              return {
                description : y[`description_${LanguageService.getLanguageCode()}`],
                image       : this.getImage(y.image),
                id          : y.id,
                position    : y.position
              }
            })                                               
          }
        });

        this.dataExist = true;
      }
    }
    return this.data;
  }
}
