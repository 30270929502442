import { ROUTES } from '../constants/routes.jsx';

class RouteService {

  static routes = [];
  static subscribeUnauthorizedCallbacks = [];

  static get defaultRoute() {
    return ROUTES.home;
  }

  static addRoute(path, component) {
    if (Array.isArray(path)) {
      path.map((item) => this.routes.push({
        path: item,
        component,
      }));
    } else {
      RouteService.routes.push({
        path,
        component,
      });
    }

    RouteService
      .subscribeUnauthorizedCallbacks
        .map((item) => item(
          RouteService.routes
        ));

    return component;
  }

  static subscribeUnauthorized(callback) {
    RouteService
      .subscribeUnauthorizedCallbacks
        .push(callback);

    return callback(RouteService.routes);
  }
}

export default RouteService;