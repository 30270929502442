
import React, { Component } from 'react';

import { ByRoute } from '../../shared/decorators/routes.jsx';
import { ROUTES } from '../../shared/constants/routes.jsx';

import './index.scss';
import './responsive.scss'
import Markdown from '../../components/markdown/Markdown.jsx';
import SubcategoryStore from '../../shared/api/subcategory.jsx';
import BusinessStore from '../../shared/api/business.jsx';
import SimpleMap from '../../components/map/map.jsx';

@ByRoute(ROUTES.businessDetails + '/:id')
class BusinessDetails extends Component {

  _isMounted = false;

  state = {
    pageData: null,
    data: null,
  }

  async componentDidMount() {
    const [pageData, business] = await Promise.all([
      SubcategoryStore.getData(),
      BusinessStore.getData(),
    ]);
    const data = business.find(x => x.id === this.props.match.params.id);
    if (!data || !data.businessBlocks.length) {
      window.history.back();
    }
    this.setState({ pageData, data });
    this._isMounted = true;
  }


  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const state = this.state;
    return state.data && (
      <div className="P-business-details G-page">
        <h4 className="G-text-center G-mt-4">{state.data.title}</h4>

        <div className="G-flex G-flex-column G-align-center">
          {state.data.businessBlocks.length && state.data.businessBlocks.map((item, index) => (
            <>
              {item.image ? (index % 2 === 0) ?
                <div className="P-details-item G-flex" key={index}>
                  <div className="P-details-image" style={{ backgroundImage: `url('${item.image}')` }}> </div>
                  <div className="P-details-description">
                    {index === 0 && <h2>{state.data.title}</h2>}
                    <Markdown data={item.description} />
                  </div>
                </div> :
                <div className="P-details-item G-flex" key={index}>
                  <div className="P-details-description">
                    <Markdown data={item.description} />
                  </div>
                  <div className="P-details-image" style={{ backgroundImage: `url('${item.image}')` }}> </div>
                </div> : <div className="P-details-item G-flex" key={index}>
                <div className="P-details-no-image P-details-description">
                  <Markdown data={item.description} />
                </div>
              </div>}
              {(item.video) &&
                <video src={item.video} controls className="G-mt-6 G-mb-6"></video>
              }

              {item.images.length ?
                <div className="P-media">
                  {item.images.map((x, i) => 
                  <div key={x + i} className="P-item" style={{ backgroundImage: `url('${x}')` }}></div>
                  )}
                </div>
                : null
              }
            </>
          ))}
          {(state.data.buttonUrl && state.data.linkButton) &&
            <a href={state.data.buttonUrl} target="blank" className="G-btn G-mt-6" >{state.data.linkButton} </a>
          }



          {(state.data.lat && state.data.lng && state.data.phone && state.data.email && state.data.address) &&
            <div className="contact-map G-mt-6">
              <div className="map">
                <SimpleMap apiKey={'AIzaSyCPgUr4CPqkq0GBGMcKDACaEufereKArn4'} lat={state.data.lat} lng={state.data.lng} />
              </div>
              <div className="P-business-info G-flex G-align-center G-justify-between">
                <div className="G-flex G-align-center"><i className="icon-phone G-mr-2" /> {state.data.phone} </div>
                <div className="G-flex G-align-center"><i className="icon-message G-mr-2" /> {state.data.email}</div>
                <div className="G-flex G-align-center"><i className="icon-location G-mr-2" /> {state.data.address}</div>
              </div>
            </div>}
        </div>
      </div>
    )
  }
}

export default BusinessDetails;
