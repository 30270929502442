import RouteService from '../services/routes.jsx';

export function ByRoute(route) {
  return (component) => {
    return RouteService.addRoute(
      route,
      component,
    );
  }
}
