import React, { Component } from 'react'

import { ByRoute } from '../../shared/decorators/routes.jsx';
import { ROUTES } from '../../shared/constants/routes.jsx';
import HomeStore from '../../shared/api/home.jsx';
import AboutUs from './components/about-us/index.jsx';

import './index.scss';
import './responsive.scss';
import Categories from './components/categories/index.jsx';
import HomeCover from '../../components/home-cover/Cover.jsx';

@ByRoute(ROUTES.home)
class Home extends Component {

  state = {
    data: null,
    imageArray: []
  }

  async componentDidMount() {
    const data = await HomeStore.getHomeData();
    this.setState({ data, imageArray: data.coverImages });
  }

  render() {
    const { state } = this;
    return state.data && (
      <div className="P-home">

        <HomeCover data={state.data} title={state.data.coverTitle} imageArray={this.state.imageArray} />

        {/* <AboutUs home={state.data} /> */}

        <Categories />

      </div>
    )
  }
}

export default Home;