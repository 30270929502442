import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown';

export default class Markdown extends Component {

  componentDidMount() {
    Array.from(this.el.getElementsByTagName('a')).forEach(x => {
      // x.setAttribute('target', '_blank');
      let href = x.getAttribute('href');

      if (href && !href.includes('http')) {
        href = 'http://' + href;
        x.setAttribute('href', href);
      }
    });
  }
  
  
  render() {
    const props = this.props;
    return (
      <div {...props} ref={ref => this.el = ref}>
        <ReactMarkdown source={props.data} />
      </div>
    )
  }
}
