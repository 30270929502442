import React, {Component} from 'react';
import './modal.scss';
import Logo from "../../../../assets/images/home_logo.png";
import GlobalStore from "../../../../shared/api/global";

class Modal extends Component {

  render() {
    const global = GlobalStore.data;
    const {props} = this;
    return (
      <div className="P-modal">
        <div className="P-background" onClick={props.close}>
        </div>
        <div className="P-content">
          <img src={Logo} alt="" />
          <h3>{global.thankYou}</h3>
          <p>{global.formMessageSent}</p>
          <button className="G-btn G-mt-2" onClick={props.close}>{global.formOk}</button>
        </div>
      </div>
    );
  }

}

export default Modal;
