import React, { Component, Fragment } from 'react'

import './index.scss';
import './responsive.scss';

import Logo from '../../assets/images/cover-logo.png';

class Cover extends Component {

  slider = null;
  state = {
    active: false,
    activeIndex: 0,
    imageArray: this.props.imageArray,
    image: this.props.image,
    toTop: false,
  }

  constructor(props) {
    super(props);
    this.cover = React.createRef();
  }

  componentDidMount() {
    if (this.props.imageArray && this.props.imageArray.length) {
      this.slider = setInterval(() => {
        if (this.state.activeIndex !== this.props.imageArray.length - 1) {
          this.setState({ activeIndex: this.state.activeIndex + 1 })
        } else {
          this.setState({ activeIndex: 0 })
        }
      }, 4000)
    }
    if (window.innerWidth > 768) {
      window.addEventListener('scroll', this.smoothScroll, false);
    }
  }

  componentWillUnmount() {
    if (this.slider) {
      clearInterval(this.slider);
    }
  }

  smoothScroll = () => {
    if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
      this.setState({ toTop: true });
    } else if (document.body.scrollTop === 0 || document.documentElement.scrollTop === 0) {
      this.setState({ toTop: false });
    }
  }

  smoothScrollFunc = (h) => {
    let i = h || 0;
    if (i < window.innerHeight) {
      setTimeout(() => {
        window.scrollTo(0, i);
        this.smoothScrollFunc(i + 100);
      }, 10);
    }
  }

  render() {
    const { title, imageArray, image } = this.props;
    const state = this.state;
    return (
      <Fragment>
        <div className={`G-cover ${state.toTop ? 'P-to-top' : 'P-to-bottom'}`} ref={this.cover}>
          <div className="P-slider-left-path">
            <div className="P-slider" style={{ transform: `translateX(-${this.state.activeIndex * 100}%)` }}>
              {imageArray ? imageArray.map(item =>
                <div className="P-item">
                  <div style={{ backgroundImage: `url('${item}')` }}> </div>
                </div>) : <div className="P-item">
                  <div style={{ backgroundImage: `url('${image}')` }}> </div>
                </div>}
            </div>
            <div className='G-container'>
              <div className="P-title-block">
                <img src={Logo} alt="" />
                {title && <h3>{title}</h3>}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Cover;