import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ByRoute } from '../../shared/decorators/routes.jsx';
import { ROUTES } from '../../shared/constants/routes.jsx';
import './index.scss';
import './responsive.scss'
import Cover from '../../components/cover/Cover.jsx';
import BusinessStore from '../../shared/api/business';
import SubcategoryStore from '../../shared/api/subcategory';
import Markdown from '../../components/markdown/Markdown.jsx';
import CategoryStore from '../../shared/api/categories.jsx';
import Logo from '../../assets/images/logo-mark.png';

@ByRoute(ROUTES.business + '/:id')

class Business extends Component {

  _isMounted = false;

  state = {
    data: null,
    category: null,
    business: null,
    hover: false,
    id: undefined,
  }

  async componentDidMount() {
    let category = await CategoryStore.getData();
    let data = await SubcategoryStore.getData();
    const business = await BusinessStore.getData();
    data = data.filter(x => x.categoryId === this.props.match.params.id);
    if (!business.length) {
      window.history.back();
    }
    this.setState({ data, business, category: category.find(x => x.id === this.props.match.params.id) });
    this._isMounted = true;

    if (window.innerWidth < 451) {
      this.setState({
        hover : false
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  hover = (id) => {
    this.setState({
      hover: true,
      id: id
    })
  }

  disableHover = () => {
    this.setState({
      hover: false
    });
  }

  render() {
    const state = this.state;
    return state.data && state.category && (

      <div className="P-business G-page">
        {/* <div className="P-slider-padding">
          <Cover title={state.category.coverTitle} image={state.category.coverImage} />
        </div> */}
        {state.data.map((x, index) => (
          <div className="P-business-items" key={index}>
            <h2>{x.title}</h2>
            <Markdown className="G-text-center G-mt-4" data={x.description}/>
            <div className="P-business-wrap G-flex G-flex-wrap G-align-center G-mt-6" >
              {x.businesses.sort((a, b) => a.position - b.position).map((item, index) => (
                <Link  to={`${ROUTES.businessDetails}/${item.id}`} className="P-business-item G-flex G-align-center G-justify-center G-mr-5 G-mt-3" key={index} onMouseEnter={() => this.hover(item.id)} onMouseLeave={this.disableHover}>
                  <div className="P-career-title G-bold">{item.title}</div>
                  <div className="P-career-background" style={{ backgroundImage: `url('${item.icon}')` }}></div>
                  <div className="P-career-bottom">
                    <img src={Logo} alt="" className='G-mt-5'/>
                    <div className="P-career-show-more">Show more</div>
                  </div>
                </Link>))}
            </div>
          </div>))}
      </div>
    )
  }
}

export default Business;
