import React, { Component, Fragment } from 'react'

import { ByRoute } from '../../shared/decorators/routes.jsx';
import { ROUTES } from '../../shared/constants/routes.jsx';

import './index.scss';
import './responsive.scss';
import SayHelloStore from '../../shared/api/say-hello.jsx';
import GlobalStore from '../../shared/api/global.jsx';
import test from '../../assets/images/contact-us.png';
import SimpleMap from '../../components/map/map.jsx';
import Modal from "./components/modal/modal";

@ByRoute(ROUTES.contactUs)
class ContactUs extends Component {

  state = {
    data: null,
    modalState: false,
    name: '',
    email: '',
    message: '',
    nameError: false,
    emailError: false,
    messageError: false,
  }

  setModalState = (modalState) => {
    this.setState({modalState});
  }

  async componentDidMount() {
    const data = await SayHelloStore.getData();
    this.setState({ data });
  }

  handleName = (event) => {
    this.setState({name: event.target.value});
  }

  handleEmail = (event) => {
    this.setState({email: event.target.value});
  }

  handleMessage = (event) => {
    this.setState({message: event.target.value});
  }

  validate = () => {
    let valid = true;

    if (this.state.name) {
      this.setState({ nameError: false });
    } else {
      this.setState({ nameError: true });
      valid = false;
    }

    if (this.state.email) {
      this.setState({ emailError: false });
    } else {
      this.setState({ emailError: true });
      valid = false;
    }

    if (this.state.message) {
      this.setState({ messageError: false });
    } else {
      this.setState({ messageError: true });
      valid = false;
    }

    return valid;
  }

  closeModalAndReset = () => {
    this.setModalState(false);
    this.setState({
      name: '',
      email: '',
      message: '',
    });
  }

  submit = () => {
    if (this.validate()) {
      this.setModalState(true);
      fetch(process.env.REACT_APP_API_URL + 'contactform', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          name: this.state.name,
          email: this.state.email,
          message: this.state.message,
        }),
      }).then();
    }
  }

  render() {
    const state = this.state;
    const global = GlobalStore.data;
    return (
      <div className="P-contact-us G-page G-flex G-flex-column">
        {state.data && <Fragment>
          <div className="P-green-bg G-flex G-align-center G-justify-around">
            <div className="P-contacts G-flex G-flex-column">
              <h2 className="G-gold G-mb-6">{global.contact}</h2>
              <div className="G-flex G-align-center G-mt-6"> <i className="icon-phone G-gold G-mr-2" /> <span className="G-white-color">{global.phone}</span></div>
              <div className="G-flex G-align-center G-mt-6"> <i className="icon-email G-gold G-mr-2" /> <span className="G-white-color">{global.email}</span></div>
              <div className="G-flex G-align-center G-mt-6"> <i className="icon-location G-gold G-mr-2" /> <span className="G-white-color">Arshakunyats 61</span></div>
            </div>
            <div className="G-flex G-flex-column">
              <img src={test} alt="" className="bgimage" />
              <div className="G-flex G-flex-column">
                <input type='text' className="G-input G-mb-2" placeholder={global.formName} onChange={this.handleName} value={state.name} />
                <input type='text' className="G-input G-mt-2" placeholder={global.formEmail} onChange={this.handleEmail} value={state.email} />
                <input type='text' className="G-input G-mt-6" placeholder={global.formMessage} onChange={this.handleMessage} value={state.message} />
              </div>
              <button className="G-btn G-mt-6" onClick={this.submit}>Send</button>
            </div>
          </div>
          <div className="contact-map">
            <div className="map">
              <SimpleMap apiKey={'AIzaSyCPgUr4CPqkq0GBGMcKDACaEufereKArn4'} lat={40.1872} lng={44.5152} />
            </div>
          </div>
        </Fragment>}
        {state.modalState ? <Modal close={this.closeModalAndReset}/> : null}
      </div>
    )
  }
}

export default ContactUs;
