import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../shared/constants/routes';
import CategoryStore from '../../../../shared/api/categories';

import './index.scss';
import './responsive.scss'
import test from '../../../../assets/images/animation-logo.png';

class Categories extends Component {

  state = {
    categories: null,
    animation : false
  }

  async componentDidMount() {
    const categories = await CategoryStore.getData();
    this.setState({ categories });
    window.addEventListener('scroll', this.isScrolledIntoView)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.isScrolledIntoView)
  }

  isScrolledIntoView = () => {
    var el = document.getElementById('myElement');
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    if (elemTop > 0) {
      this.setState({
        animation : true
      })
    }
  }


  render() {
    const { state } = this;
    return (
      state.categories &&
      <div className="P-home-categories G-flex " id="myElement">
        <div className="P-left ">
          <img src={test} alt="" className={` ${state.animation ? 'animation' : ''}`}/>
          <div className="G-flex G-flex-column G-justify-center">
            {state.categories.map((item, index) => (
              <Link to={`${ROUTES.business}/${item.id}`} className="P-category-item" key={index}>
                <div className="P-category-block G-flex G-align-center" style={{ backgroundImage: `url('${item.coverImage}')` }}>
                  <div className="P-category-text">
                    {item.coverTitle}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="P-right"></div>
      </div>
    )
  }
}

export default Categories;