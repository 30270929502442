import { HttpService } from "../services/connection.jsx"
import BaseStore from "./baseStore.jsx";
import { LanguageService } from "../services/languages.jsx";

export default class HomeStore extends BaseStore {

  static dataExist = false;

  static data = {
    coverTitle        : '',
    coverImage        : '',
    aboutTitle        : '',
    aboutDescr        : '',
    coverImages       : [],
    ourPartnersImages : [],
    ourPartnersTitle  : ''
  }

  static getHomeData = async () => {
    if (!this.dataExist) {
      let data = await HttpService.find('home');
      if (data) {
        this.data.coverTitle = data[`coverTitle_${LanguageService.getLanguageCode()}`];
        this.data.coverImages = (data.coverImages || []).map(x => this.getImage(x));
        this.data.aboutDescr = data[`aboutDescr_${LanguageService.getLanguageCode()}`];
        this.data.aboutTitle = data[`aboutTitle_${LanguageService.getLanguageCode()}`];
        this.data.ourPartnersTitle = data[`ourPartnersTitle_${LanguageService.getLanguageCode()}`];

        this.data.ourPartnersImages =  (data.ourPartnersImages || []).map(x => this.getImage(x));

        this.dataExist = true;
      }
    }
    return this.data;
  }
}
