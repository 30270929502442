import { HttpService } from "../services/connection.jsx"
import BaseStore from "./baseStore.jsx";
import { LanguageService } from "../services/languages.jsx";

export default class CharityPagesStore extends BaseStore {

  static dataExist = false;

  static data = {
    coverTitle : '',
    coverImage : '',
    showMore   : ''
  }

  static getData = async () => {
    if (!this.dataExist) {
      let data = await HttpService.find('charity-page');
      if (data) {
        this.data.coverTitle = data[`coverTitle_${LanguageService.getLanguageCode()}`];
        this.data.coverImage = this.getImage(data.coverImage);;
        this.data.showMore = data[`showMore_${LanguageService.getLanguageCode()}`];

        this.dataExist = true;
      }
    }
    return this.data;
  }
}
