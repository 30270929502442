
export const ROUTES = {
  home            : '/home',
  contactUs       : '/contact-us',
  about           : '/about-us',
  careers         : '/careers',
  careersDetails  : '/careers/details',
  news            : '/news',
  newsDetails     : '/news/details',
  charity         : '/charity',
  charityDetails  : '/charity/details',
  business        : '/business',
  businessDetails : '/business/details',
  login           : '/login',
}
