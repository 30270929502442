import React, { Component, Fragment } from 'react';

import { ByRoute } from '../../shared/decorators/routes.jsx';
import { ROUTES } from '../../shared/constants/routes.jsx';
import AboutStore from '../../shared/api/about';

import './index.scss';
import './responsive.scss';
import Cover from '../../components/cover/Cover';
import test from '../../assets/images/animation-logo.png';
import CategoryStore from '../../shared/api/categories.jsx';
import SubcategoryStore from '../../shared/api/subcategory.jsx';
import BusinessStore from '../../shared/api/business.jsx';
import Markdown from '../../components/markdown/Markdown.jsx';
import TeamStore from '../../shared/api/team.jsx';

@ByRoute(ROUTES.about)
class AboutUs extends Component {

  constructor(props) {
    super(props);
    this.SolutionBlock = React.createRef();
    this.ei = React.createRef();
  }

  state = {
    data: null,
    categories: null,
    subCategories: null,
    business: null,
    team: null,
    hover: false,
    id: undefined,
    step: 0,
    animation: false
  }

  async componentDidMount() {
    const data = await AboutStore.getData();
    const categories = await CategoryStore.getData();
    const subCategories = await SubcategoryStore.getData();
    const business = await BusinessStore.getData();
    const team = await TeamStore.getData();
    this.setState({ data, categories, subCategories, business, team });
    window.addEventListener('scroll', this.isScrolledIntoView)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.isScrolledIntoView)
    this._isMounted = false;
  }

  next = () => {
    const stepCount = Math.ceil(this.state.team.length / 4);
    if (this.state.step !== stepCount - 1) {
      this.setState({
        step: this.state.step + 1
      })
    }
  }

  previous = () => {
    if (this.state.step !== 0) {
      this.setState({
        step: this.state.step - 1
      })
    }
  }

  showNext = () => {
    const stepCount = Math.ceil(this.state.team.length / 4);
    return this.state.step !== stepCount - 1;
  }

  isScrolledIntoView = () => {
    var el = document.getElementById('myElement');
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    if (elemTop > 0) {
      this.setState({
        animation: true
      })
    }
  }

  render() {
    const { state } = this;
    return  (
      <div className="P-about-us G-page">
        {state.data && <Fragment>
          <div className="P-slider-padding">
            <Cover title={state.data.coverTitle} image={state.data.coverImage} />
          </div>
          <div className="P-about-work G-flex G-align-center">
            <div className="P-left">
              <img src={test} alt="" className={` ${state.animation ? 'animation' : ''}`} />
              <div className="G-flex G-flex-column G-justify-center G-align-center" id="myElement">
                <h3>{state.data.workTitle}</h3>
                <div className="P-work-image G-mt-5" style={{ backgroundImage: `url('${state.data.workImage}')` }}></div>
                <Markdown className="G-mt-5" data={state.data.workDescr} />
              </div>
            </div>
            <div className="P-right"></div>
          </div>
          <div className="P-about-team G-flex G-justify-center G-align-center G-flex-column">
            <h2 className="G-mt-5 G-gold">{state.data.ourTeam}</h2>
            <div className="P-team-slider-block">
              <div className="P-team-slider G-flex G-align-center G-mt-5" style={{ left: state.step * - 100 + '%' }}>
                {state.team.map((item, index) => (
                  <div key={index} className="P-team-item G-flex G-flex-column G-align-center">
                    <div className="P-team-image" style={{ backgroundImage: `url('${item.image}')` }}></div>
                    <div className="P-team-name G-mt-2 G-bold">{item.fullName}</div>
                    <div className="P-team-position">{item.position}</div>
                  </div>
                ))}
              </div>
            </div>
            {!!state.step && <i className="icon-arrow_left_1 left-btn" onClick={this.previous} />}
            {this.showNext() && <i className="icon-arrow_right_1 right-btn" onClick={this.next} />}
          </div>
        </Fragment>}
      </div>
    )
  }
}

export default AboutUs;
