import { HttpService } from "../services/connection.jsx"
import BaseStore from "./baseStore.jsx";
import { LanguageService } from "../services/languages.jsx";

export default class CategoryStore extends BaseStore {

  static dataExist = false;

  static data = []

  static getData = async () => {
    if (!this.dataExist) {
      let data = await HttpService.find('categories');
      if (data) {
        this.data = data.map(x => {
          return {
            id         : x.id,
            coverImage : this.getImage(x.coverImage),
            icon       : this.getImage(x.icon),
            iconWhite  : this.getImage(x.icon_white),
            listImage  : this.getImage(x.listImage),
            coverTitle : x[`coverTitle_${LanguageService.getLanguageCode()}`],
            subCategories : x.subCategories.map(y => {
              return {
                title_en   : y[`title_${LanguageService.getLanguageCode()}`],
                coverImage : this.getImage(y.coverImage),
                position   : y.position,
                id         : y.id
              }
            })
          }
        });

        this.dataExist = true;
      }
    }
    return this.data;
  }
}
