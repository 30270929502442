import React, { Component, Fragment } from 'react'
import Lottie from 'react-lottie';

import './index.scss';
import './responsive.scss';

import HomeLogo from '../../assets/images/home_logo.png'
import HomeTitle from '../../assets/images/home_title.png'
import ScrollJSON from '../../assets/json/scroll.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: ScrollJSON,
};

class HomeCover extends Component {
  

  bool = false;
  slider = null;
  state = {
    toTop: false,
    active: false,
    activeIndex: 0,
    imageArray: this.props.imageArray,
    image: this.props.image,
    logoAnimation : false,
    smoothScroll      : false
  }

  constructor(props) {
    super(props);
    this.cover = React.createRef();
  }

  componentDidMount() {
    if (this.props.imageArray && this.props.imageArray.length) {
      this.slider = setInterval(() => {
        if (this.state.activeIndex !== this.props.imageArray.length - 1) {
          this.setState({ activeIndex: this.state.activeIndex + 1 })
        } else {
          this.setState({ activeIndex: 0 })
        }
      }, 4000)
    }
    // window.addEventListener('scroll', this.hideCoverDescriptionOnScroll)
    window.addEventListener('scroll', this.scrollAnimation, false);
    if (window.innerWidth > 850) {
      window.addEventListener('scroll', this.smoothScroll, false);    }
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.hideCoverDescriptionOnScroll())

    if (this.slider) {
      clearInterval(this.slider);
    }
  }

  scrollAnimation = () => {
    if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
      this.setState({
        logoAnimation: true,
      });
    } else {
      this.setState({
        logoAnimation: false,
      });
    }
  };

  smoothScroll = () => {
    if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
      this.setState({ toTop: true });
    } else if (document.body.scrollTop === 0 || document.documentElement.scrollTop === 0) {
      this.setState({ toTop: false });
    }
  }

  smoothScrollFunc = (h) => {
    let i = h || 0;
    if (i < window.innerHeight) {
      setTimeout(() => {
        window.scrollTo(0, i);
        this.smoothScrollFunc(i + 100);
      }, 10);
    }
  }

  render() {
    const { imageArray, image, data } = this.props;
    const state = this.state;

    return (
      <Fragment>
        <div className={`G-home-cover G-gradient ${state.toTop ? 'P-to-top' : 'P-to-bottom'}`} ref={this.cover}>
          <div className="P-slider-left-path">
            <div className="P-slider" style={{ transform: `translateX(-${this.state.activeIndex * 100}%)` }}>
              {imageArray ? imageArray.map(item =>
                <div className="P-item" key="{item}">
                  <div style={{ backgroundImage: `url('${item}')` }}> </div>
                </div>) : <div className="P-item">
                  <div style={{ backgroundImage: `url('${image}')` }}> </div>
                </div>}
            </div>
            <div className='G-container G-flex G-align-center G-justify-center'>
              {/* <div className="P-title-block">
                {title && <h3>{title}</h3>}
              </div> */}

            </div>
          </div>
          <div className={`P-home-animation G-flex G-flex-column G-align-center ${state.logoAnimation ? 'P-scroll-animation-hide' : 'P-scroll-animation-show'}`}>
            <img src={HomeLogo} className='P-home-logo' alt="" />
            <img src={HomeTitle} className='P-home-title' alt="" />
            {/* <p>{data.aboutTitle}</p> */}
            <p className='P-about-us'>{data.aboutDescr}</p>
          </div>
          <div className="P-lottie-anim">
              <Lottie options={defaultOptions} />
          </div>

        </div>
      </Fragment>
    )
  }
}

export default HomeCover;