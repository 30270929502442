import { HttpService } from "../services/connection.jsx"
import BaseStore from "./baseStore.jsx";

export default class SidebarStore extends BaseStore {

  static dataExist = false;

  static data = {
    description: '',
    image: '',
    menu: '',
  }

  static getData = async () => {
    if (!this.dataExist) {
      let data = await HttpService.find('sidebars');
      if (data && data[0]) {
        data = data[0];
        this.data.image = this.getImage(data.image);
        this.data.description = data.description;
        this.data.menu = data.menu;
        this.dataExist = true;
      }
    }
    return this.data;
  }
}
