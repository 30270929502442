import { HttpService } from "../services/connection.jsx"
import BaseStore from "./baseStore.jsx";
import { LanguageService } from "../services/languages.jsx";

export default class SubcategoryStore extends BaseStore {

  static dataExist = false;

  static data = []

  static getData = async () => {
    if (!this.dataExist) {
      let data = await HttpService.find('sub-categories');
      if (data) {
        this.data = data.map(x => {
          return {
            id               : x.id,
            coverImage       : this.getImage(x.category.coverImage),
            position         : x.position,
            title            : x[`title_${LanguageService.getLanguageCode()}`],
            coverTitle       : x.category[`coverTitle_${LanguageService.getLanguageCode()}`],
            description      : x[`description_${LanguageService.getLanguageCode()}`],
            categoryId       : x.category.id,
            icon             : this.getImage(x.category.icon),
            iconWhite        : this.getImage(x.category.icon_white),
            businesses       : x.businesses.map(y => {
              return {
                buttonUrl  : y.buttonUrl,
                lat        : y.lat,
                lng        : y.lat,
                linkButton : y.linkButton,
                position   : y.position,
                id         : y.id,
                title      : y[`title_${LanguageService.getLanguageCode()}`],
                icon       : this.getImage(y.icon)
              }
            })                                               
          }
        });

        this.dataExist = true;
      }
    }
    return this.data;
  }
}
