
import React, { Component } from 'react';

import { ByRoute } from '../../shared/decorators/routes.jsx';
import { ROUTES } from '../../shared/constants/routes.jsx';

import './index.scss';
import './responsive.scss'
import Markdown from '../../components/markdown/Markdown.jsx';
import CharityPagesStore from '../../shared/api/charity-page.jsx';
import CharitiesStore from '../../shared/api/charities.jsx';

@ByRoute(ROUTES.charityDetails + '/:id')
class CharityDetails extends Component {

  state = {
    pageData : null,
    data     : null,
  }

  async componentDidMount() {
    const [pageData, charities] = await Promise.all([
      CharityPagesStore.getData(),
      CharitiesStore.getData()
    ]);
    const data = charities.find(x => x.id === this.props.match.params.id);
    if (!data || !data.charityBlocks.length) {
      window.history.back();
    }
    this.setState({ pageData, data });
  }

  render() {
    const state = this.state;
    state.data && state.data.charityBlocks.sort((a, b) => a.position - b.position);

    return (state.pageData && state.data) ? (
      <div className="P-news-details G-page G-flex G-flex-column">
        {state.data.charityBlocks.map((item, index) => (
          item.image ? (index % 2 === 0) ? <div className="P-details-item G-flex" key={index}>
            <div className="P-details-image" style={{ backgroundImage: `url('${item.image}')` }}> </div>
            <div className="P-details-description">
              {index === 0 && <h2>{state.data.title}</h2>}
              <Markdown data={item.description} />
            </div>
          </div> : <div className="P-details-item G-flex" key={index}>
              <div className="P-details-description">
                <Markdown data={item.description} />
              </div>
              <div className="P-details-image" style={{ backgroundImage: `url('${item.image}')` }}> </div>
            </div> : <div className="P-details-item G-flex" key={index}>
              <div className="P-details-no-image P-details-description G-mb-6">
                <Markdown data={item.description} />
              </div>
            </div>
        ))}
      </div>
    ) : <div></div>
  }
}

export default CharityDetails;
